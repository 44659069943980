<template>
    <div>
        <div class="detail">
            <div class="details-top" v-if="isPlay">
                <div class="top-img">
                    <img :src="gameInfo.logo" alt="">
                </div>
                <div class="top-title">
                    {{gameInfo.title}}
                </div>
                <div class="top-rat">
                    <van-rate
                    v-model="gameInfo.rating"
                    readonly
                    :size="15"
                    color="#FFC900"
                    void-icon="star"
                    void-color="#eee"
                    />
                    <div class="rat">
                        {{gameInfo.rating}}
                    </div>
                </div>
                <div class="top-btn" @click="()=>{isPlay = false}">
                    PLAY
                </div>                
            </div>
            <div class="gamewin" v-else>
                <iframe
                style="width:100%"
                :src="baseUrl+gameInfo.url"
                frameborder="0"></iframe>
            </div>
            <div class="details-description">
                <div class="des-top">Description :</div>
                <div class="des-body warp-6">{{gameInfo.synopsis}}</div>
            </div>
            <div class="cont-st">
                <div class="cont-st-h">
                    <p>RECOMMEND FOR YOU :</p>
                    <!-- <div>more</div> -->
                </div>
                <div class="cont-st-card" v-for="item in gameList" :key="item.id" @click="goDetails(item)">
                    <img :src="item.logo" alt="">
                    <p class="no-warp">{{item.title}}</p>
                    <!-- <van-rate
                    v-model="value"
                    readonly
                    :size="10"
                    color="#FF3434"
                    void-icon="star"
                    void-color="#eee"
                    /> -->
                </div>
                <span v-for="item in 8" :key="item+'s'"></span>
            </div>
        </div>
    </div>
</template>

<script>
import { isMobile, scrollToTop } from "@/utils/api"
import { getGameById } from "@/api/games"

    export default {
        data() {
            return {
                value:4,
                gameList:[],
                gameInfo:{},
                spanNum:0,
                isPlay:true,
                baseUrl:''
            }
        },
        mounted() {
            let id = this.$route.query.id

            this.getGameInfo(id);

            if(isMobile()){
                this.spanNum = 3-2
            }else{
                this.spanNum = 11-2
            }

            // this.baseUrl = process.env.VUE_APP_BASE_GAME
            this.baseUrl = 'https://'+window.location.hostname;
            scrollToTop(0);
        },
        methods: {
            async getGameInfo(id){
                let { game, similarGames} = await getGameById({id})
                this.gameInfo = game;
                this.gameList = similarGames;
            },
            getGame(){

            },
            goDetails(item){
                this.gameInfo = item;
                this.isPlay = true;
                scrollToTop(0);

            }
        },
    }
</script>

<style lang="less" scoped>
.detail{
    padding: 20px;
    box-sizing: border-box;
    .details-top{
        margin-bottom: 22px;
        position: relative;
        div{
            width: 320px;
            margin: 0 auto;
            text-align: center;
        }
        .top-img{
            width: 320px;
            height: 320px;
            border-radius: 24px;
            // margin:24px auto 4px;
            overflow: hidden;
            img{
                width: 100%;
                height: 100%;
            }
        }
        .top-title{
            position: absolute;
            top:0;
            left: 50%;
            transform: translateX(-50%);
            border-radius: 24px;
            // width: 106px;
            background: rgba(0, 0, 0, 0.5);
            height: 320px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 500;
            font-size: 16px;
            color: #FFF;
            line-height: 320px;
            text-align: center;
            font-style: normal;
            // margin: 4px auto 6px;
        }
        .top-rat{
            width: 115px;
            margin:10px auto 16px;
            display: flex;
            justify-content: center;
            // align-items: center;
            .rat{
                width: 21px;
                font-family: PingFangSC, PingFang SC;
                font-weight: 400;
                font-size: 14px;
                color: #818181;
                // line-height: 20px;
                text-align: left;
                font-style: normal;
            }
        }
        .top-btn{
            width: 271px;
            height: 45px;
            background: #D8C8B1;
            border-radius: 10px;
            cursor: pointer;
            font-family: PingFangSC, PingFang SC;
            font-weight: 500;
            font-size: 16px;
            color: #FFFFFF;
            line-height: 45px;
            font-style: normal;
            text-transform: uppercase;
        }
    }
    .gamewin{
        width: 100%;
        height: calc(100ch - 50px);
        iframe{
            width: 100%;
            height: 100%;
        }
    }
    .details-description{
        padding: 15px;
        box-sizing: border-box;
        background: #F4F4F6;
        border-radius: 20px;
        margin-bottom: 35px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        font-size: 16px;
        color: #000;
        line-height: 22px;
        font-style: normal;
        .des-top{
            font-size: 16px;
        }
        .des-body{
            font-size: 14px;
        }
    }
    .cont-st{
        // padding: 0 20px;
        box-sizing: border-box;
        background: #F4F4F6;
        border-radius: 20px;
        margin-bottom: 25px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        .cont-st-h{
            width: 100%;
            // background: #504BA9;
            border-radius: 10px;
            display: flex;
            align-items: center;
            p{
                width: 100%;
                height: 25px;
                font-family: PingFangSC, PingFang SC;
                font-weight: 500;
                font-size: 14px;
                color: #000000;
                line-height: 25px;
                text-align: left;
                font-style: normal;
                margin: 15px 0 0 15px;
            }
            div{
                margin-right: 12px;
                font-family: PingFangSC, PingFang SC;
                font-weight: 400;
                font-size: 16px;
                color: #FFFFFF;
                line-height: 22px;
                text-align: left;
                font-style: normal;
                text-transform: uppercase;
            }
        }
        .cont-st-card{
            margin: 7px 0;
            // padding-top: 6px;
            box-sizing: border-box;
            width: 101px;
            // height: 152px;
            // background: #FFFFFF;
            // border-radius: 11px;
            text-align: center;
            img{
                width: 101px;
                height: 101px;
                border-radius: 15px;
            }
            p{
                font-family: PingFangSC, PingFang SC;
                font-weight: 600;
                font-size: 14px;
                color: #000;
                line-height: 20px;
                text-align: left;
                font-style: normal;
            }
        }
        span{
            width: 106px;
        }
    }
    .no-warp{
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .warp-6{
        display: -webkit-box;
        -webkit-line-clamp: 6;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}
</style>