<template>
    <div>
        <div class="top-class">
            <div class="top-class-card"  v-for="item in topList.games" :key="item.id" @click="goselect(item.key)">
                <img :src="item.logo" alt="">
                <p>{{item.key_name}}</p>
            </div>
        </div>
        <adContent></adContent>
        <div class="cont-st mid-list">
            <div class="cont-st-h">
                <p>{{rankList.title}}</p>
                <div @click="goselect(rankList.title)">SEE ALL</div>
            </div>
            <div class="mid-list-rank">
                <div class="rank-card"  v-for="items in rankList.games" :key="items.id">
                    <img :src="items.logo" alt="">
                    <p class="no-warp">{{items.title}}</p>
                    <van-rate
                    v-model="items.rating"
                    readonly
                    :size="10"
                    color="#FFC900"
                    void-icon="star"
                    void-color="#eee"
                    />
                </div>
            </div>
            <div class="mid-list-card" v-for="items in newList.games" :key="items.id">
                <img :src="items.logo" alt="">
                <div class="mid-c-mid">
                    <p class="no-warp">{{items.title}}</p>
                    <div class="warp-2">{{items.synopsis}}</div>
                    <div>
                        <van-rate
                        v-model="items.rating"
                        readonly
                        :size="10"
                        color="#FFC900"
                        void-icon="star"
                        void-color="#eee"
                        />
                    </div>
                </div>
                <div class="play" @click="goDetails(items)">
                    Play
                </div>
            </div>
        </div>
        <div class="cont-st">
            <div class="cont-st-h">
                <p>{{firstList.title}} GAMES</p>
                <div @click="goselect(firstList.title)">more</div>
            </div>
            <div class="cont-st-card" v-for="item in firstList.games" :key="item.id" @click="goDetails(item)">
                <img :src="item.logo" alt="">
                <p class="no-warp">{{item.title}}</p>
                <div>
                    <van-rate
                    v-model="item.rating"
                    readonly
                    :size="10"
                    color="#FFE141"
                    void-icon="star"
                    void-color="#eee"
                    />
                    <span>{{item.rating}}</span>
                </div>
            </div>
            <span v-for="item in 5" :key="item"></span>
        </div>
        <div class="cont-st cont-list">
            <div class="cont-st-h">
                <p>{{gameList.title}} GAMES</p>
                <div @click="goselect(gameList.title)">more</div>
            </div>
            <div class="cont-list-card" v-for="items in gameList.games" :key="items.id" @click="goDetails(items)">
                <img :src="items.logo" alt="">
                <p class="no-warp">{{items.title}}</p>
            </div>
            <span v-for="item in spanNum" :key="item+'s'"></span>
        </div>
    </div>
</template>

<script>
// import topClass from '@/utils/topClass'
import { getRandomNumbers, isMobile, scrollToTop } from "@/utils/api"
import eventBus from '@/utils/eventBus'
import { getGameByKey, getGame, getGameList } from "@/api/games"
import adContent from "@/components/adContent/index.vue";
    export default {
        components: {
            adContent

        },
        data() {
            return {
                // topClass,
                gameList:[],
                value:4,
                allList:[],
                gameListC:[],
                firstList:{},
                newList:{},
                rankList:{},
                topList:{},
                spanNum:0
            }
        },
        mounted() {
            if(isMobile()){
                this.spanNum = 3-2
            }else{
                this.spanNum = 11-2
            }
            let menukey = this.$route.params.menuKey;
            let is
            if(menukey){
                this.getMenuGame(menukey)
                is = 0
            }else{
                is = 1
            }
            this.getList(is);
            eventBus.$on('selectMenu',key=>{                    
                this.getMenuGame(key)
            });
        },
        methods: {
            goDetails(item){
                this.$router.push({
                    path:'/details',
                    query:{
                        id:item.id
                    }
                })
            },
            async getList(is){
                let gameList = await getGame();
                
                if(is){
                    this.gameList = {
                        title: 'All',
                        games:gameList
                    }
                }

                let rankList = await getGame({count:3});
                this.rankList = {
                    title: 'Ranking',
                    games:rankList
                }
                
                let newList = await getGame({count:4});
                this.newList = {
                    title: 'New',
                    games:newList
                }

                let firstList = await getGame({count:12});
                this.firstList = {
                    title: 'Hot',
                    games:firstList
                }
                
                let res = await getGameList();

                let toplist = [];
                for (const key in res) {
                    toplist.push(res[key][0])
                }
                this.topList = {
                    title: 'topClass',
                    games:toplist
                }                
            },
            getRandomGames(str,end,len){
                let gamelist = getRandomNumbers(str,end,len);

                gamelist.forEach((item,i)=>{
                    gamelist.splice(i,1,this.allList[item])
                })

                return gamelist;
            },
            async getMenuGame(name){
                let games = await getGameByKey({category:name});
                this.gameList = {
                    title: name,
                    games
                }
                
                eventBus.$emit('showmenu',0)
                eventBus.$emit('showfenlei',0)

                scrollToTop(2300)
            },
            goselect(item){
                this.$router.push({
                    path: '/selectGame',
                    query:{
                        id: 'more',
                        key: item
                    }
                })
            }
        },
    }
</script>

<style lang="less" scoped>
.top-class{
    padding: 20px 20px 0;
    box-sizing: border-box;
    display: grid;
    grid-template-columns: repeat(4,1fr);
    grid-gap: 21px;
    text-align: center;
    .top-class-card{
        width: 100%;
        img{
            width: 100%;
            border-radius: 50%;
        }
        p{
            font-family: PingFangSC, PingFang SC;
            font-weight: 500;
            font-size: 14px;
            color: #000000;
            line-height: 20px;
            font-style: normal;
        }
    }
}
.cont-st{
    padding: 0 20px;
    box-sizing: border-box;
    margin-bottom: 25px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .cont-st-h{
        width: 100%;
        height: 40px;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        p{
            font-family: PingFangSC, PingFang SC;
            font-weight: 500;
            font-size: 18px;
            color: #FFBA00;
            line-height: 25px;
            text-align: left;
            font-style: normal;
            // margin-left: 12px;
        }
        div{
            margin-right: 12px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            font-size: 16px;
            color: #DAC6B0;
            line-height: 22px;
            text-align: left;
            font-style: normal;
            text-transform: uppercase;
        }
    }
    .cont-st-card{
        margin: 12px 0;
        padding-top: 6px;
        box-sizing: border-box;
        width: 152px;
        border-radius: 11px;
        text-align: center;
        img{
            width: 152px;
            height: 152px;
            border-radius: 15px;
        }
        p{
            font-family: PingFangSC, PingFang SC;
            font-weight: 500;
            font-size: 14px;
            color: #000000;
            line-height: 20px;
            text-align: left;
            font-style: normal;
        }
        div{
            display: flex;
            justify-content: space-between;
            align-items: center;
            span{
                font-family: PingFangSC, PingFang SC;
                font-weight: 400;
                font-size: 14px;
                color: #818181;
                line-height: 20px;
                text-align: left;
                font-style: normal;
            }
        }
    }
    >span{
        width: 152px;
    }
}
.cont-ed{
    .cont-ed-card{
        width: 162px;
        height: 86px;
        padding: 5px;
        margin: 8px 0 0 0;
        box-sizing: border-box;
        background: #FFFFFF;
        border-radius: 10px;
        display: flex;
        align-content: center;
        justify-content: space-between;
        .ed-card-l{
            width: 76px;
            height: 76px;
            border-radius: 10px;
            img{
                width: 100%;
                height: 100%;
            }
        }
        .ed-card-r{
            width: 70px;
            overflow: hidden;
            p{
                margin-bottom: 14px;
                font-family: PingFangSC, PingFang SC;
                font-weight: 500;
                font-size: 14px;
                color: #000000;
                line-height: 20px;
                text-align: left;
                font-style: normal;
            }
            .play{
                margin: 0 auto;
                width: 63px;
                height: 21px;
                text-align: center;
                line-height: 21px;
                background: #504BA9;
                border-radius: 12px;

                font-family: PingFangSC, PingFang SC;
                font-weight: 500;
                font-size: 9px;
                color: #FFFFFF;
                font-style: normal;
                text-transform: uppercase;
            }
        }
    }
}
.mid-list{
    .mid-list-rank{
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-content: center;
        .rank-card{
            padding: 6px 8px 0;
            box-sizing: border-box;
            width: 106px;
            height: 152px;
            background: #F3EBDF;
            border-radius: 11px;
            text-align: center;
            img{
                width: 90px;
                height: 90px;
            }
            p{
                font-family: PingFangSC, PingFang SC;
                font-weight: 500;
                font-size: 14px;
                color: #000000;
                line-height: 20px;
                font-style: normal;
            }
        }
    }
    .mid-list-card{
        width: 335px;
        height: 80px;
        margin: 7px 0;
        background: #FFF;
        display: flex;
        align-items: center;
        justify-content: space-between;
        img{
            width: 80px;
            height: 80px;
            background: #D8D8D8;
            border-radius: 13px;
            // border: 1px solid #FFFFFF;
        }
        .mid-c-mid{
            width: 162px;
            p{
                font-family: PingFangSC, PingFang SC;
                font-weight: 500;
                font-size: 14px;
                color: #000000;
                text-align: left;
                font-style: normal;
            }
            div{
                font-family: PingFangSC, PingFang SC;
                font-weight: 400;
                font-size: 12px;
                color: #747474;
                line-height: 17px;
                text-align: left;
                font-style: normal;
            }
        }
        .play{
            width: 74px;
            height: 25px;
            background: #D8C8B1;
            border-radius: 5px;
            line-height: 25px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 500;
            font-size: 10px;
            color: #FFFFFF;
            text-align: center;
            font-style: normal;
        }
    }
}
.cont-list{
    .cont-list-card{
        width: 100px;
        margin: 16px 0 0 0;
        img{
            width: 100px;
            height: 100px;
            border-radius: 15px;
            border: 2px solid #FFFFFF;
        }
        p{
            font-family: PingFangSC, PingFang SC;
            font-weight: 600;
            font-size: 14px;
            color: #000;
            line-height: 20px;
            text-align: left;
            font-style: normal;
        }
    }
    span{
        width: 100px;
    }
}
.no-warp{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.warp-2{
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}
</style>